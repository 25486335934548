.img {
    height: 150px;
    width: 150px ;
    object-fit: contain;
    border-radius: 50%;
    flex: 0.5;
}

.content {
    height: 1000px;
    width: 100%;
    background-color: var(--color-bg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container {
    width: 800px;
    height: 300px;
    min-height: fit-content;
    background-color: none;
    display: flex;
    align-items: center;
    padding: 20px;
    margin: 20px;
    justify-content: space-around;
}


.text {
    flex: 0.5;
}


@media screen and (max-width: 1050px) {
    .container {
        width: 500px;
    }
}

@media screen and (max-width: 650px) {
   


}

@media screen and (max-width: 490px) {
    .container {
        width: 150px;
        height: 100px;
        min-height: fit-content;
        background: none;
        display: flex;
        align-items: center;
        padding: 20px;
        margin: 20px;
        justify-content: space-around;
    }

    .img {
        height: 60px;
        width: 70px ;
        object-fit: contain;
        border-radius: 50%;
        flex: 0.5;
    }

    .content {
        height: 600px;
        width: 60%;
        background-color: var(--color-bg);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .content h1 {
        display: none;
    }

}