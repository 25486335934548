.main_carousel {
    width: 100%;
  }
  
  .item {
    height: 33rem;
    min-width: 20rem;
    padding: 10px;
  }
  
  .item:hover {
    transform: scale(1.05);
  }
  
  
  .item img {
    height: 100%;
    width: 100%;
    border-radius: 2rem;
    pointer-events: none;
  }
  
  
  .inner-carousel {
    display: flex;
  }
  
  
  .carousel {
    overflow: hidden;
    margin: 2% 15%;
    cursor: grab;
  }
  
  .gradient__text {
    justify-content: center;
    font-family: var(--font-family);
    font-size: 50px;
    text-align: center;
  }
  
  @media screen and (max-width: 1050px) {
    .carousel {
      cursor: grab;
      overflow: hidden;
      margin: 2% 23%;
    }
  
    .item {
      height: 30rem;
      min-width: 15rem;
      padding: 10px;
    }
  
    
  
    .gradient__text {
      justify-content: center;
      font-family: var(--font-family);
      font-size: 30px;
      text-align: center;
    }
    
  }
  
  @media screen and (max-width: 650px) {
    
  }
  
  @media screen and (max-width: 490px) {
    .carousel {
      cursor: grab;
      overflow: hidden;
      margin: 0% 15%;
      width: 230px;
      display: flex;
    }
  
    .item {
      height: 26rem;
      min-width: 15rem;
      padding: 10px;
    }
  
    .gradient__text {
      justify-content: center;
      font-family: var(--font-family);
      font-size: 25px;
      text-align: center;
    }
    
  }