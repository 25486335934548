.contact {
    width: 100%;
    background: var(--color-bg);
    height: 100%;
}

.contact .contact-content{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background: var(--color-bg);
    width: 100%;
    height: 450px;
    border-radius: 20px;
}

.contact-content {
    display: flex;
}

.max-width{
    padding: 30px 80px;
    margin: auto;
    background: var(--color-bg);
    margin-top: 50px;
    flex-wrap: wrap;
}

.contact .title{
    position: relative;
    text-align: center;
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 60px;
    padding-bottom: 20px;
    font-family: 'Ubuntu', sans-serif;
    color: var(--color-orange);
}

.contact .title::before{
    content: "";
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 180px;
    height: 3px;
    background: var(--color-orange);
    transform: translateX(-50%);
}

.contact .title::after{
    position: absolute;
    bottom: -8px;
    left: 50%;
    font-size: 20px;
    color: var(--color-orange);
    padding: 0 5px;
    background: var(--color-white);
    transform: translateX(-50%);
}

.contact .title::after{
    content: "get in touch";
}
.contact .contact-content .column{
    width: calc(50% - 30px);
}
.contact .contact-content .text{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    color: var(--color-orange);
}
.contact .contact-content .left p{
    color: var(--color-white);
    font-size: 25px;
}

.contact .contact-content .row{
    display: flex;
    height: 65px;
    align-items: center;
}
.contact .contact-content .row .info{
    margin-left: 30px;
}

.contact .contact-content .info .head{
    font-weight: 500;
    color: var(--color-orange)
}
.contact .contact-content .info .sub-title{
    color: var(--color-white);
    font-size: 18px;
}
.contact .right form .fields{
    display: flex;
}
.contact .right form .field,
.contact .right form .fields .field{
    height: 45px;
    width: 100%;
    margin-bottom: 15px;
}
.contact .right form .textarea{
    height: 80px;
    width: 100%;
}
.contact .right form .name{
    margin-right: 10px;
}
.contact .right form .field input,
.contact .right form .textarea textarea{
    height: 100%;
    width: 100%;
    border: 1px solid lightgrey;
    border-radius: 6px;
    outline: none;
    padding: 0 15px;
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
    transition: all 0.3s ease;
}
.contact .right form .field input:focus,
.contact .right form .textarea textarea:focus{
    border-color: #b3b3b3;
}
.contact .right form .textarea textarea{
  padding-top: 10px;
  resize: none;
}
.contact .right form .button-area{
  display: flex;
  align-items: center;
}
.right form .button-area button{
  color: #fff;
  display: block;
  width: 160px!important;
  height: 45px;
  outline: none;
  font-size: 18px;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  flex-wrap: nowrap;
  background: var(--color-orange);
  border: 2px solid crimson;
  transition: all 0.3s ease;
}
.right form .button-area button:hover{
  color: var(--color-orange);
  background: none;
}

@media screen and (max-width: 1050px) {
    .contact .contact-content {
        font-size: 20px;
        width: 700px;
        margin-left: -3rem;
        margin-top: -3rem;
    }

    .contact .contact-content .left {
        display: grid;
    }

    .contact .contact-content .left p{
       font-size: 25px;
       width: 700px;
    }

    .contact .contact-content .icons {
        display: grid;
        line-height: 1.3;
    }



    .contact .contact-content .info .sub-title{
        font-size: 25px;
        margin-top: 5px;
        margin-bottom: 5px;
    }


    .contact .contact-content .info .head {
        font-size: 25px;
        margin-bottom: -6px;
    }


    .contact .contact-content .text{
        font-size: 25px;
    }

    
}

@media screen and (max-width: 650px) {
    
    .max-width {
        padding: 0px 23px;
    }

    .contact .right form .fields{
        flex-direction: column;
    }
    
    .contact-content {
        display: flex;
    }
}

@media screen and (max-width: 490px) {

    .contact .contact-content {
        font-size: 10px;
        width: 360px;
        align-items: center;
        margin-left: -1rem;
    }
    .contact .contact-content .left {
        display: grid;
    }

    .contact .contact-content .left p{
        font-size: 20px;
        margin-left: 2rem;
        width: 300px;
    }

    

    .contact .contact-content .icons {
        display: grid;
    }

    .contact .contact-content .icons .sub-title {
        font-size: 20px;
    }

    .contact .contact-content .icons .head {
        font-size: 20px;
    }

}