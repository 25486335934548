.app__gallery {
    display: flex;
    flex-direction: row;
    background: var(--color-bg);
    padding: 0.5rem 0 0.3rem 0.5rem;
    height: 370px;
}


.app__gallery-images {
    flex: 1;
    display: flex;
    max-width: 100%;
    position: relative;
}

.app__gallery-images_container {
    display: flex;
    flex-direction: row;
    width: 600px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.app__gallery-images_container::-webkit-scrollbar {
    display: none;
}

.app__gallery-images_card {
    position: relative;
    min-width: 400px;
    height: 447px;
    margin-right: 1rem;
}

.gallery__image-icon {
    color: #fff;
    font-size: 2rem;
    opacity: 0;
    transition: .5s ease;
    cursor: pointer;
}

.app__gallery-images_card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: 0.5s ease;
}

.app__gallery-images_card:hover img {
    opacity: 0.35;
}

.app__gallery-images_card:hover .gallery__image-icon {
    opacity: 1;
}

.app__gallery-images_arrows {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 1rem;
    position: absolute;
    bottom: 5%;
}

.gallery__arrow-icons {
    color:var(--color-orange);
    font-size: 3rem;
    cursor: pointer;
    background-color: var(--color-black);
    border-radius: 5px;
}

.gallery__arrow-icons:hover {
    color: var(--color-white);
}

@media screen and (min-width: 2000px) {
    .app__gallery {
        padding: 0.5rem 0 0.3rem 0.1rem;
    }

    .app__gallery-images_container {
        display: flex;
        flex-direction: row;
        width: 800px;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .app__gallery-images_card {
        min-width: 800px;
        height: 400px;
    }
}

@media screen and (max-width: 1150px) {
    .app__gallery {
        padding: 0.5rem 0 0.3rem 0.1rem;
    }

    .app__gallery-images_container {
        display: flex;
        flex-direction: row;
        width: 800px;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .app__gallery-images_card {
        min-width: 300px;
        height: 400px;
    }
}

@media screen and (max-width: 850px) {
    .app__gallery {
        padding: 0.5rem 0 0.3rem 0.1rem;
    }

    .app__gallery-images_container {
        display: flex;
        flex-direction: row;
        width: 620px;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .app__gallery-images_card {
        min-width: 200px;
        height: 250px;
    }
}

@media screen and (max-width: 650px) {
    .app__gallery {
        padding: 0.2rem 0 0.1rem 0.1rem;
    }

    .app__gallery-images_container {
        display: flex;
        flex-direction: row;
        width: 320px;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .app__gallery-images_card {
        min-width: 150px;
        height: 250px;
    }
}