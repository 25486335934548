.main_carousel {
    width: 100%;
    background: var(--color-footer);
    padding: 10px 20px;
}
  
.item {
    height: 33rem;
    min-width: 20rem;
    padding: 10px;
}
  
.item:hover {
    transform: scale(1.05);
}
  
  
.item img {
    height: 100%;
    width: 100%;
    border-radius: 2rem;
    pointer-events: none;
}
  
  
.inner-carousel {
    display: flex;
    background: var(--color-footer);
}
  
  
.carousel {
    overflow: hidden;
    margin: 2% 15%;
    cursor: grab;
    background: var(--color-footer);
}
  
.gradient__text {
    justify-content: center;
    font-family: var(--font-family);
    font-size: 50px;
    text-align: center;
}
  
@media screen and (max-width: 1050px) {
    
    .main_carousel .carousel {
      margin-left: 9rem;
    }
  
    .carousel {
      cursor: grab;
      overflow: hidden;
      margin: 2% 10%;
      width: 500px;
    }

    .main_carousel {
      margin-right: 30rem;
    }
   
    .item {
      height: 30rem;
      min-width: 15rem;
      padding: 10px;
    }
  
    
  
    .gradient__text {
      justify-content: center;
      font-family: var(--font-family);
      font-size: 30px;
      text-align: center;
    }
    
  }
  

  
@media screen and (max-width: 490px) {


    .main_carousel .carousel {
      margin-left: 3rem;
      width: 250px;
    }

    .carousel {
      cursor: grab;
      overflow: hidden;
      display: flex;
      margin: 5% 10%;
    }
  
    .item {
      height: 26rem;
      min-width: 15rem;
    }
  
    .gradient__text {
      justify-content: center;
      font-family: var(--font-family);
      font-size: 25px;
      text-align: center;
    }
    
}