.header {
    display: flex;
    flex-direction: row;
    background: var(--color-bg);
    width: 100%;
}

.header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;
    margin-top: -3rem;
}

.icon_link .gmail_icon p  {
    font-size: 20px;
    color: var(--color-white);
    position: absolute;
    margin-top: -7rem;
    background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 
    73% 55%/150% 150% no-repeat;
    margin-left: -5rem;
    padding: 5px 10px;
    border-radius: 20px;
}

.icon_link .github_icon p  {
    font-size: 20px;
    color: var(--color-white);
    position: absolute;
    margin-top: -7rem;
    margin-right: -3rem;
    background: #684eeb;
    padding: 5px 10px;
    border-radius: 20px;
}

.icon_link .fb_icon p  {
    font-size: 20px;
    color: var(--color-white);
    position: absolute;
    margin-top: -7rem;
    margin-left: -4rem;
    background: #3b5998;
    padding: 5px 10px;
    border-radius: 20px;
}

.icon_link .insta_icon p  {
    font-size: 20px;
    color: var(--color-white);
    position: absolute;
    margin-top: -7rem;
    margin-left: -9px;
    padding: 5px 10px;
    border-radius: 20px;
    background: #f09433; 
background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

.icon_link .tweet_icon p  {
    font-size: 20px;
    color: var(--color-white);
    position: absolute;
    margin-top: -7rem;
    margin-left: -2rem;
    background: #00acee;
    padding: 5px 10px;
    border-radius: 20px;
}


.BgAnimation__svg {
    height: 35rem;
}

.header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 50px;
    line-height: 35px;
    letter-spacing: -0.04em;
}

.header-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 30px;
    line-height: 28px;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;

    display: flex;
    flex-direction: row;
}


.header-content__input .contact_icon p {
    font-size: 25px;
    color: var(--color-white);
    position: absolute;
    margin-top: -5rem;
    margin-left: -13rem;
    background: var(--color-orange);
    padding: 20px 40px;
    border-radius: 20px;
}



.header-content__input button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    background: var(--color-orange);
    border: 2px solid #FF4820;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;

    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.header-content__input button:hover {
    flex: 0.6;
    width: 100%;
    min-height: 55px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 23px;
    line-height: 30px;
    background: var(--color-hover);
    border: var(--color-hover);
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.icon_link {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin-top: 1rem;
}

.icon_link img {
    width: 60px;
    height: 45px;
    margin: 0 1rem;
}

.icon_link img:hover {
    transform: scale(1.08);
    cursor: pointer;
}



@media screen and (max-width: 1500px) {
    .header-content {
        margin-top: -4rem;
    }

    .header {
        flex-direction: column;
    }

    .BgAnimation__svg {
        display: none;
    }

    .header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 850px) {

    .header-content h1 {
        font-size: 40px;
        line-height: 30px;
    }
}

@media screen and (max-width: 650px) {
    .header-content h1 {
        font-size: 40px;
        line-height: 60px;
    }

    .header-content p {
        font-size: 16px;
        line-height: 24px;
    }

    .BgAnimation__svg {
        display: none;
    }
    
    .header-content__people {
        flex-direction: column;
    }

    .header-content__people p {
        margin: 0;
    }

    .header-content__input input,
    .header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {

    .header-content {
        margin-top: -4rem;
    }

    .header-content h1 {
        font-size: 25px;
        line-height: 20px;
    }

    .icon_link img {
        width: 35px;
        height: 27px;
        margin: 0 1rem;
    }

    .icon_link img:hover {
        width: 40px;
        height: 30px;
        margin: 0 1rem;
    }
    

    .header-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .BgAnimation__svg {
        display: none;
    }

    .header-content__input input,
    .header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }

    .icon_link .gmail_icon p {
        margin-top: -5rem;
        margin-left: -2rem;
    }

    .icon_link .github_icon p {
        margin-top: -5rem;
        margin-left: -1rem;
    }

    .icon_link .fb_icon p {
        margin-top: -5rem;
        margin-left: -6rem;
    }

    .icon_link .insta_icon p {
        margin-top: -5rem;
    }

    .icon_link .tweet_icon p {
        margin-top: -5rem;
        margin-left: -7rem;
    }

    .header-content__input .contact_icon p {
        font-size: 15px;
        color: var(--color-white);
        position: absolute;
        margin-top: -4rem;
        margin-left: -10rem;
        background: var(--color-orange);
        padding: 10px 20px;
        border-radius: 20px;
    }

}